import * as Yup from 'yup';

export const password = Yup.string()
  .required('É preciso colocar a senha!')
  .min(8, 'A senha precisa de, no mínimo, 8 caracteres')
  .matches(/(?=.*[0-9])/, 'A senha precisa ter pelo menos um número')
  .matches(/(?=.*[a-z])/, 'A senha precisa ter pelo menos uma letra minúscula')
  .matches(/(?=.*[A-Z])/, 'A senha precisa ter pelo menos uma letra maiúscula');

// Por favor, não setar o name de senha diferente de password
export const passwordConfirmation = Yup.string()
  .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
  .required('É preciso confirmar a senha');

export const email = Yup.string()
  .email('E-mail inválido')
  .required('É preciso colocar um e-mail!');

export const name = Yup.string().required('É preciso colocar um nome!');

export const moneyRegex = {
  ptBR: /^([1-9]{1}[\d]{0,2}(\.[\d]{3})*(,[\d]{0,2})?|[1-9]{1}[\d]{0,}(,[\d]{0,2})?|0(,[\d]{0,2})?|(,[\d]{1,2})?)$/,
};

const yupValidators = {
  email,
  name,
  password,
  passwordConfirmation,
  moneyRegex,
};

export { yupValidators };
