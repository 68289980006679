import gql from 'graphql-tag';

export const CREATE_CONSULTANT = gql`
  mutation CREATE_CONSULTANT(
    $email: String!
    $name: String!
    $roleOrganization: String!
  ) {
    createConsultant(
      email: $email
      name: $name
      roleOrganization: $roleOrganization
    ) {
      consultant {
        id
      }
    }
  }
`;

export const CREATE_PASSWORD = gql`
  mutation CREATE_PASSWORD(
    $email: String!
    $newPassword: String!
    $token: String!
  ) {
    createConsultantPassword(
      email: $email
      newPassword: $newPassword
      token: $token
    ) {
      message
    }
  }
`;

export const DISABLE_CONSULTANT = gql`
  mutation {
    disableConsultant {
      message
    }
  }
`;

export const LOGIN_CONSULTANT = gql`
  mutation LOGIN_CONSULTANT($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      user {
        consultant {
          organization {
            id
          }
        }
      }
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation REQUEST_RESET_PASSWORD($email: String!) {
    requestResetConsultantPassword(email: $email) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($currentPassword: String!, $newPassword: String!) {
    resetPassword(curretPassword: $currentPassword, newPassword: $newPassword) {
      user {
        id
      }
    }
  }
`;

export const SUPPORT_REQUEST = gql`
  mutation SUPPORT_REQUEST(
    $email: String!
    $name: String!
    $phone: String
    $messageSupport: String!
  ) {
    supportRequest(
      email: $email
      name: $name
      phone: $phone
      messageSupport: $messageSupport
    ) {
      message
    }
  }
`;

export const UPDATE_CONSULTANT_PASSWORD = gql`
  mutation UPDATE_CONSULTANT_PASSWORD(
    $currentPassword: String!
    $newPassword: String!
  ) {
    updateConsultantPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      message
    }
  }
`;

export const UPDATE_CONSULTANT = gql`
  mutation UPDATE_CONSULTANT(
    $id: Int!
    $activeConsultant: Boolean
    $email: String!
    $name: String!
    $roleOrganization: String
  ) {
    updateConsultant(
      id: $id
      activeConsultant: $activeConsultant
      email: $email
      name: $name
      roleOrganization: $roleOrganization
    ) {
      consultant {
        name
        roleOrganization
        avatar
      }
      message
    }
  }
`;

export const UPLOAD_CONSULTANT_PICTURE = gql`
  mutation UPLOAD_CONSULTANT_PICTURE($avatar: Upload!) {
    uploadConsultantPicture(avatar: $avatar) {
      message
    }
  }
`;
